import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { Col, Row, Form, Button, FormGroup, Label, Input, Spinner, Modal,
  ModalHeader,
  ModalBody } from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { v4 as uuidv4 } from "uuid";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
  },
}));

function ProduccionCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_INSUMOS = process.env.REACT_APP_URL_INSUMOS;
  const URL_COLABORADORES = process.env.REACT_APP_URL_COLABORADORES;
  const URL_MAQUINAS = process.env.REACT_APP_URL_MAQUINAS;
  const URL_PRODUCCION = process.env.REACT_APP_URL_PRODUCCION;
  const URL_PEDIDOS = process.env.REACT_APP_URL_PEDIDOS;
  const URL_PROCESOS = process.env.REACT_APP_URL_PROCESOS;
  const URL_INSUMOS_PRODUCCION = process.env.REACT_APP_URL_INSUMOS_PRODUCCION;
  const URL_WHATSAPP = process.env.REACT_APP_URL_WHATSAPP;

  const [insumos, setInsumos] = useState([]);
  const [pedidos, setPedidos] = useState([]);
  const [numeroInterno, setNumeroInterno] = useState("");
  const [selectedPedido, setSelectedPedido] = useState("");
  const [selectedPedidoNumero, setSelectedPedidoNumero] = useState("");
  const [selectedCliente, setSelectedCliente] = useState("");
  const [selectedClienteNombre, setSelectedClienteNombre] = useState("");
  const [procesos, setProcesos] = useState([]);
  const [cantidadUltimoProceso, setCantidadUltimoProceso] = useState(0);
  const [selectedProceso, setSelectedProceso] = useState("");
  const [selectedProcesoNombre, setSelectedProcesoNombre] = useState("");
  const [colaboradores, setColaboradores] = useState([]);
  const [selectedColaborador, setSelectedColaborador] = useState("");
  const [maquinas, setMaquinas] = useState([]);
  const [selectedMaquina, setSelectedMaquina] = useState("");
  const [totalHilo, setTotalHilo] = useState(0);


  const [sacarInsumos, setSacarInsumos] = useState(false);

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  const [fecha, setFecha] = useState("");
  const [observaciones, setObservaciones] = useState("");

  const classes = useStyles();
  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      insumos: "",
      unidad: "",
      cantidad: 0,
      tiposInsumos: "",
    },
  ]);

  const [validaBoton, setValidaBoton] = useState(true);

  useEffect(() => {
    axios
      .get(URL_INSUMOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allInsumos = res.data;
        setInsumos(allInsumos);
      })
      .catch((err) => {
        console.log(err);
      });
   
      axios
      .get(URL_COLABORADORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allColaboradores = res.data;
        setColaboradores(allColaboradores);
      })
      .catch((err) => {
        console.log(err);
      });

      axios
      .get(`${URL_PEDIDOS}Activos`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allPedidos = res.data;
        setPedidos(allPedidos);
      })
      .catch((err) => {
        console.log(err);
      });

      axios
      .get(`${URL_PROCESOS}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allProcesos = res.data;
        setProcesos(allProcesos);
      })
      .catch((err) => {
        console.log(err);
      });

      axios
      .get(`${URL_MAQUINAS}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allMaquinas = res.data;
        setMaquinas(allMaquinas);
      })
      .catch((err) => {
        console.log(err);
      });
     
  }, []);

  function enviarWhatsApp(){
    axios.post(
      `${URL_WHATSAPP}SendMsg`,
      {
        number: process.env.REACT_APP_TELEFONO_SUPER_ADMIN,
        message: `Hola Isaac,
Se acaba de registrar un nuevo proceso de produccion.
Cliente: ${selectedClienteNombre}
Pedido: ${selectedPedidoNumero}
Proceso: ${selectedProcesoNombre}`,
      },
      {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      }
    );

  }

  const saveProduccion = async (event) => {
    event.preventDefault();
    setValidaBoton(false);
    toggleProgreso();
    let totalProd = inputFields.length

    try {
      enviarWhatsApp()
        await axios
        .post(
          URL_PRODUCCION,
          {
            fecha,
            clientes:  selectedCliente,
            operador: selectedColaborador,
            procesos: selectedProceso,
            pedidos: selectedPedido,
            maquinas: selectedMaquina,
            fechaInicio: fecha,
            fechaFin: fecha,
            observaciones,
            cantidadInicial: totalHilo + cantidadUltimoProceso,
            cantidadFinal: 0,
            status: "Abierto",
            procesos:selectedProceso,
            numeroInterno: numeroInterno

          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then((data) => {
          if(sacarInsumos){
          
          inputFields.map((a) => {
            if(a.insumos != "" && a.cantidad !=0){
            axios.post(
              URL_INSUMOS_PRODUCCION,
              {
                produccion: data.data._id,
                pedidos: selectedPedido,
                insumos: a.insumos,
                cantidad: parseFloat(a.cantidad),
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            )
            .then(()=>{
              totalProd = totalProd -1
              if(totalProd == 0 ){
                axios.post(
                  URL_LOGS,
                  {
                    tipo: "Crear Produccion",
                    detalle: `${data.data._id}`,
                    user: user.id,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                    },
                  }
                )
                  .then(()=>{
                    Swal.fire("Good job!", "Creado con exito", `success`);
                    setTimeout(() => {
                      window.location.reload();
                    }, 1000);
                  })
              }
            })
          }else{ 
            totalProd = totalProd -1
            if(totalProd == 0 ){
              axios.post(
                URL_LOGS,
                {
                  tipo: "Crear Produccion",
                  detalle: `${data.data._id}`,
                  user: user.id,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                  },
                }
              )
                .then(()=>{
                  Swal.fire("Good job!", "Creado con exito", `success`);
                  setTimeout(() => {
                    window.location.reload();
                  }, 1000);
                })
            }
          }
          });  
        } else {
          Swal.fire("Good job!", "Creado con exito", `success`);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response.data}`,
          });
          console.log(error);
          setValidaBoton(true);
        });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    }
  };

  const handleAddFields = () => {
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        insumos: "",
        unidad: "",
        cantidad: 0,
        tiposInsumos: "",
      },
    ]);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
  };

  const handleChangeInput = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  function BuscaInsumo(id, event){
    insumos.map((a)=>{
      if(a._id == event.target.value){
        let idInsumo = a._id
        let unidadInsumo = a.unidad
        let tiposInsumos = a.tiposInsumos[0].name
        handleChangeInputInsumo(id, idInsumo, unidadInsumo, tiposInsumos)
      }
    })
  }

  const handleChangeInputInsumo = (id, idInsumo, unidadInsumo, tiposInsumos) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.insumos = idInsumo;
        i.unidad = unidadInsumo
        i.tiposInsumos = tiposInsumos
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };
  
function jalaPedido(id){
  setSelectedPedido(id)
  pedidos.map((a)=>{
    if(a._id == id){
      setSelectedCliente(a.clientes[0]._id)
      setSelectedPedidoNumero(a.idPedido)
      setSelectedClienteNombre(a.clientes[0].nombre_comercial)
    }
  })
  
  axios
  .get(`${URL_PRODUCCION}Pedido/${id}`, {
    headers: {
      Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
    },
  })
  .then((res) => {
    const allProducciones = res.data;

    allProducciones.sort((a, b) => {
      return new Date(b.createdAt) - new Date(a.createdAt);
    });

    const ultimoProduccion = allProducciones[0];

    const cantidadFinalUltimo = ultimoProduccion.cantidadFinal;

    setCantidadUltimoProceso(cantidadFinalUltimo);

  })
  .catch((err) => {
    console.log(err);
  });

}



function jalaProceso(id){
  setSelectedProceso(id)
  procesos.map((a)=>{
    if(a._id == id){
      setSelectedProcesoNombre(a.name)
    }
  })
}

function ActualizaTotales(){
let hilo = inputFields.filter((e) => e.unidad == "Kilos");
let THil = hilo.map((c) => parseFloat(c.cantidad));
let Th = THil.reduce((t, total, index) => t + total, 0);

setTotalHilo(Th)
}

  return (
    <>
      <Header />
      <br />
      <br />
      {user.produccion_create ? (
        <div className="card container col-12">
          <h3 align="center">Nueva Produccion</h3>
          <Form onSubmit={saveProduccion}>
            <Row>
              <Col md={3}>
                <Label className="mr-sm-2">Fecha</Label>
                <Input
                   className="col-sm-12"
                  type="date"
                  placeholder="Fecha"
                  value={fecha}
                  required
                  onChange={(e) => {
                    setFecha(e.target.value);
                  }}
                />
              </Col>
            
              <Col md={3}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Numero Interno
                </Label>
                <Input
                  className="col-sm-12"
                  type="text"
                  placeholder="Numero Interno"
                  value={numeroInterno}
                  required
                  onChange={(e) => {
                    setNumeroInterno(e.target.value);
                  }}
                />
              </Col>
            </Row>
              <Row>
              <Col md={3}>
                <Label className="mr-sm-2">Pedidos</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedPedido}
                  required
                  onChange={(e) => {
                    jalaPedido(e.target.value);
                  }}
                >
                  <option value="0">Selecciona</option>
                  {pedidos
                    .sort((a, b) => (a.idPedido > b.idPedido ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.idPedido} / {a.clientes[0].nombre_comercial} / {a.ordenCliente }</option>;
                    })}
                </Input>
              </Col>
              <Col md={3}>
                <Label className="mr-sm-2">Procesos</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedProceso}
                  required
                  onChange={(e) => {
                    jalaProceso(e.target.value);
                  }}
                >
                  <option value="0">Selecciona</option>
                  {procesos
                    .map((a) => {
                      if(a._id != "65e8d37cee637618e4a980fc"){
                      return <option value={a._id}>{a.name}</option>;
                    }})}
                </Input>
              </Col>
              <Col md={3}>
              <Label>Operador</Label>
              <Input
                type="select"
                value={selectedColaborador}
                onChange={(e) => {
                  setSelectedColaborador(e.target.value)
                }}
              >
                <option value="">Selecciona </option>
                {colaboradores
                  .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                  .map((a) => {
                    if(a.puestos[0].name != "Ventas"){
                      return <option value={a._id}>{a.nombre} {a.apellido}</option>;
                    }
                    
                  })}
              </Input>
            </Col>
            <Col md={3}>
              <Label>Maquinas</Label>
              <Input
                type="select"
                value={selectedMaquina}
                onChange={(e) => {
                  setSelectedMaquina(e.target.value)
                }}
              >
                <option value="">Selecciona </option>
                {maquinas
                  .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                  .map((a) => {
                      return <option value={a._id}>{a.nombre}</option>;
                    
                  })}
              </Input>
            </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Observaciones
                </Label>
                <Input
                  type="textarea"
                  placeholder="Observaciones"
                  value={observaciones}
                  required
                  onChange={(e) => {
                    setObservaciones(e.target.value);
                  }}
                />
              </Col>
            </Row>

            <br />
            <Col md={3}>
            <Input
              type="checkbox"
               onChange={(event) => setSacarInsumos(event.currentTarget.checked)}
              checked={sacarInsumos}
            />

            <h4 className="subMenuTitulo">Deseas Agregar Materiales?</h4>
            </Col>

            <br />
            {sacarInsumos?(
              <>

 
              <Row>
                <Col md={2}>
                  <Label className="mr-sm-2">Insumos</Label>
                </Col>
                <Col md={1}>
                  <Label className="mr-sm-2">Unidad</Label>
                </Col>
                <Col md={1}>
                  <Label className="mr-sm-2">Cantidad</Label>
                </Col>
              </Row>

              {inputFields.map((inputField) => (
                <div key={inputField.id}>
                  <Row>
                    <Col md={2}>
                      <Input
                        bsSize="sm"
                        name="insumos"
                        type="select"
                        value={inputField.insumos}
                        required
                        onChange={(event)=>{BuscaInsumo(inputField.id, event)}}
                      >
                        <option value="">Selecciona un Insumo</option>
                        {insumos
                          .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                          .map((a) => {
                            return (
                              <option value={a._id}>
                                {a.nombre} / {a.codigo}
                              </option>
                            );
                          })}
                      </Input>
                    </Col>
                    <Col md={1}>
                        <Input
                         bsSize="sm"
                          name="unidad"
                          type="string"
                          value={inputField.unidad}
                          disabled
                        >
                        </Input>
                      </Col>
                   


                    <Col md={1}>
                      <Input
                        bsSize="sm"
                        name="cantidad"
                        type="number"
                        placeholder="Cantidad"
                        value={inputField.cantidad}
                        required
                        onChange={(event) =>
                          handleChangeInput(inputField.id, event)
                        }
                      />
                    </Col>

                    <Col>
                      <Button
                        size="sm"
                        className="btn"
                        color="danger"
                        disabled={inputFields.length === 1}
                        onClick={() => handleRemoveFields(inputField.id)}
                        tabindex="-1"
                      >
                        <i class="fas fa-minus"></i>
                      </Button>
                      <Button 
                      size="sm"
                      className="btn"
                      color="info"
                      onClick={handleAddFields}
                      tabindex="-1">
                      <i class="fas fa-plus"></i>
                      </Button>
                    </Col>
                  </Row>
                </div>
              ))}

            <Col md={6}>
              <h4 id="logoutBoton">
              Total Hilo {totalHilo} kg.
              </h4>
              </Col>
          
          </>
          ):undefined}
           <br />
            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
            <Button
              href="/ListadoProduccion"
              className="btn btn-danger"
              id="botonListado"
            >
              Regresar
            </Button>
          </Form>
          <br />
        <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
             <h4> Este proceso puede tardar varios minutos.
              <br />
              Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
              </div>
            </ModalBody>
          </Modal>
        </div>
      ) : undefined}
    </>
  );
}

export default ProduccionCreate;
