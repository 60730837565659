import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { Col, Row, Form, Button, FormGroup, Label, Input } from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import { jsPDF } from "jspdf";
import "jspdf-autotable";

import { v4 as uuidv4 } from "uuid";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
  },
}));

function OrdenesCompraCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_INSUMOS = process.env.REACT_APP_URL_INSUMOS;
  const URL_PROVEEDORES = process.env.REACT_APP_URL_PROVEEDORES;
  const URL_ORDENES_COMPRA = process.env.REACT_APP_URL_ORDENES_COMPRA;
  const URL_ARTICULOS_OC = process.env.REACT_APP_URL_ARTICULOS_OC;
  const URL_WHATSAPP = process.env.REACT_APP_URL_WHATSAPP;

  const [insumos, setInsumos] = useState([]);
  const [moneda, setMoneda] = useState("MXN");
  const [proveedores, setProveedores] = useState([]);
  const [selectedProveedor, setSelectedProveedor] = useState("");
  const [selectedProveedorNombre, setSelectedProveedorNombre] = useState("");

  const [fecha, setFecha] = useState("");
  const [plazo, setPlazo] = useState(0);
  const [fecha_compromiso, setFechaCompromiso] = useState("");
  const [observaciones, setObservaciones] = useState("NA");
  const [impuestos, setImpuestos] = useState(0);
  const [total_general, setTotalGeneral] = useState(0);
  const [total_kilos, setTotalKilos] = useState(0);
  const [total_metros, setTotalMetros] = useState(0);
  const classes = useStyles();
  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      insumos: "",
      insumosNombre: "",
      unidad: "",
      minimo: 0,
      maximo: 0,
      cantidad: 0,
      precio: 0,
      descuento: 0,
      total: 0,
    },
  ]);

  const [validaBoton, setValidaBoton] = useState(true);

  useEffect(() => {
    axios
      .get(URL_INSUMOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allInsumos = res.data;
        setInsumos(allInsumos);
      })
      .catch((err) => {
        console.log(err);
      });
   
    axios
      .get(URL_PROVEEDORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allProveedores = res.data;
        setProveedores(allProveedores);
      })
      .catch((err) => {
        console.log(err);
      });

   
  }, []);

  function enviarWhatsApp(numero){

    let subTotal = total_general

    let iva = 0
    if(impuestos>0){
    iva = (subTotal * impuestos)/100
    }

    let totFinal = subTotal + iva

    const data = inputFields.map((a) => {
      return [
        a.insumosNombre,
        a.unidad,
        new Intl.NumberFormat("en-US").format(a.cantidad),
        "$" + new Intl.NumberFormat("en-US").format(a.precio),
        "$" + new Intl.NumberFormat("en-US").format(a.total),
       ]
  });
  const dataPDF = Object.values(data);
  const dataPDFLimpia = dataPDF.filter(function (el) {
    return el != null;
  });
  const doc = new jsPDF("landscape");
  var img = new Image();
  img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
  doc.addImage(img, "png", 230, 30, 45, 15);
  var img2 = new Image();
  img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
  doc.addImage(img2, "png", 120, 15, 60, 25);
  doc.text(`Orden de Compra # ${numero}`, 20, 20);
  doc.setFontSize(12)
  doc.text(`Fecha ${fecha}`, 20, 25);
  doc.text(`Proveedor ${selectedProveedorNombre}`, 20, 30);
  doc.text(`Plazo ${plazo} días Compromiso ${fecha_compromiso}`, 20, 35);
  doc.text(`Kilos ${new Intl.NumberFormat("en-US").format(total_kilos)}`, 20, 40)
  doc.autoTable({
    head: [["Insumo","Unidad", "Cantidad", "Precio", "Total"]],
    body: dataPDFLimpia,
    startY: 45,
    foot: [
      [
        "",
        "",
        "",
        `SubTotal` ,
        "$" + new Intl.NumberFormat("en-US").format(subTotal),
      ],
      [
        "",
        "",
        "",
        // "",
        `Impuestos ${impuestos}%` ,
        "$" + new Intl.NumberFormat("en-US").format(iva),
      ],
      [
        "",
        // "",
        "",
        "",
        `Total` ,
        "$" + new Intl.NumberFormat("en-US").format(totFinal),
      ],
    ],
    showFoot: "lastPage",
  });

  // doc.save(`OC-${numero}.pdf`);

      var attW = doc.output("arraybuffer");
      let params = new URLSearchParams({
        message:`Hola, se ha generado una Orden de Compra #${numero}`,
          fileName:`OC-${numero}.pdf`
      })
      axios
    .post(
    `${URL_WHATSAPP}SendPDFAdmin?${params.toString()}`,
    attW,
    {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem(
          "app_token"
        )}`,
        "Content-Type": "application/octet-stream",
      },
    }
    )

  }

  const saveOrdenCompra = async (event) => {
    event.preventDefault();
    setValidaBoton(false);

    let subTotal = total_general

    let iva = 0
    if(impuestos>0){
    iva = (subTotal * impuestos)/100
    }

    let totFinal = subTotal + iva

    let totalInsu = inputFields.length
    
    try {
     
      await axios
        .post(
          URL_ORDENES_COMPRA,
          {
            fecha,
            proveedores: selectedProveedor,
            plazo,
            fecha_compromiso,
            fecha_puerto_est: fecha_compromiso,
            fecha_entrega_est: fecha_compromiso,
            impuestos,
            iva,
            total_general: totFinal,
            subTotal,
            observaciones,
            total_kilos,
            total_metros,
            autorizado: "No",
            tipo:"Nacional",
            noOrdenProveedor:"NA",
            tipoCambioEstimado:0,
            moneda
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then((data) => {
          enviarWhatsApp(data.data.idOrdenCompra);
          inputFields.map((a) => {
            axios.post(
              URL_ARTICULOS_OC,
              {
                ordenesCompra: data.data._id,
                insumos: a.insumos,
                cantidad: parseFloat(a.cantidad),
                recibido: 0,
                pendiente_recibir: parseFloat(a.cantidad),
                precio: parseFloat(a.precio),
                descuento: 0,
                total: parseFloat(a.total),
                embarcado:0,
                pendiente_embarcar:0
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            ).then(() => {
            totalInsu = totalInsu - 1
            if(totalInsu == 0){
              Swal.fire("Good job!", "Creado con exito", `success`);
              setTimeout(() => {
                window.location.reload();
              }, 3000);
            }
            
          }).catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
            setValidaBoton(true);
          })
        })
          axios.post(
            URL_LOGS,
            {
              tipo: "Crear Orden de Compra",
              detalle: `${selectedProveedor} ${total_general}`,
              user: user.id,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          );
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response.data}`,
          });
          console.log(error);
          setValidaBoton(true);
        });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    }
  };
  const handleAddFields = () => {
    const ultimo = inputFields[inputFields.length-1];
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        insumos: ultimo.insumos,
        insumosNombre: ultimo.insumosNombre,
        unidad: ultimo.unidad,
        minimo: ultimo.minimo,
        maximo: ultimo.maximo,
        cantidad: 0,
        precio: ultimo.precio,
        total: 0,
      },
    ]);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
    let mts = values.filter(e=>e.unidad == "Metros")
    let totalMts = mts.map((c) => parseFloat(c.cantidad));
    let TM = totalMts.reduce((t, total, index) => t + total, 0);
    setTotalMetros(TM);
    let KG = values.filter(e=>e.unidad == "Kilos")
    let totalKG = KG.map((c) => parseFloat(c.cantidad));
    let TK = totalKG.reduce((t, total, index) => t + total, 0);
    setTotalKilos(TK);
    let importes = values.map((c) => parseFloat(c.total));
    let TG = importes.reduce((t, total, index) => t + total, 0);
    setTotalGeneral(TG);
  };

  const handleChangeInput = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
        i.total = i.cantidad * i.precio;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales()
  };

 

  function BuscaInsumo(id, event){
    insumos.map((a)=>{
      if(a._id == event.target.value){
        let idInsumo = a._id
        let unidadInsumo = a.unidad
        let minimoInsumo = a.minimo
        let maximoInsumo = a.maximo
        let insumosNombre = a.nombre
        handleChangeInputInsumo(id, idInsumo, unidadInsumo, insumosNombre, minimoInsumo, maximoInsumo)
      }
    })
  }

  const handleChangeInputInsumo = (id, idInsumo, unidadInsumo, insumosNombre, minimoInsumo, maximoInsumo) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.insumos = idInsumo;
        i.unidad = unidadInsumo
        i.minimo = minimoInsumo
        i.maximo = maximoInsumo
        i.insumosNombre = insumosNombre
        i.total = i.cantidad * i.precio;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales()
  };


  function ActualizaTotales(){
        let mts = inputFields.filter(e=>e.unidad == "Metros")
        let totalMts = mts.map((c) => parseFloat(c.cantidad));
        let TM = totalMts.reduce((t, total, index) => t + total, 0);
        setTotalMetros(TM);
        let KG = inputFields.filter(e=>e.unidad == "Kilos")
        let totalKG = KG.map((c) => parseFloat(c.cantidad));
        let TK = totalKG.reduce((t, total, index) => t + total, 0);
        setTotalKilos(TK);
        let importes = inputFields.map((c) => parseFloat(c.total));
        let TG = importes.reduce((t, total, index) => t + total, 0);
        setTotalGeneral(TG);
  }

  function jalaProveedor(id){
    setSelectedProveedor(id)
    proveedores.map((a)=>{
      if(a._id == id){
        setSelectedProveedorNombre(a.nombre_comercial + " - " + a.razon_social)
      }
    })
  }

  return (
    <>
      <Header />
      <br />
      <br />
      {user.oc_create ? (
        <div className="card container col-12">
          <h3 align="center">Nueva Orden de Compra</h3>
          <Form onSubmit={saveOrdenCompra}>
            <Row>
              <Col md={3}>
                <Label>Fecha</Label>
                <Input
                  type="date"
                  placeholder="Fecha"
                  value={fecha}
                  required
                  onChange={(e) => {
                    setFecha(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label className="mr-sm-2">Proveedor</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedProveedor}
                  required
                  onChange={(e) => {
                    jalaProveedor(e.target.value);
                  }}
                >
                  <option value="0">Selecciona un Proveedor</option>
                  {proveedores
                    .sort((a, b) => (a.nombre_comercial > b.nombre_comercial ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.nombre_comercial}</option>;
                    })}
                </Input>
              </Col>
              <Col md={3}>
                <Label>Plazo</Label>
                <Input
                  type="text"
                  placeholder="Plazo"
                  value={plazo}
                  required
                  onChange={(e) => {
                    setPlazo(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Fecha Compromiso Entrega</Label>
                <Input
                  type="date"
                  placeholder="Fecha Compromiso"
                  value={fecha_compromiso}
                  required
                  onChange={(e) => {
                    setFechaCompromiso(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row>
            <Col md={3}>
              <Label for="exampleEmail" className="mr-sm-2">
                Impuestos
              </Label>
              <Input
                type="select"
                value={impuestos}
                onChange={(e) => {
                  setImpuestos(e.target.value);
                }}
              >
                <option value="">Selecciona</option>
                 <option value={0}>No</option>
                 <option value={16}>Si</option>
              </Input>
            </Col>
            <Col md={3}>
              <Label for="exampleEmail" className="mr-sm-2">
                Moneda
              </Label>
              <Input
                type="select"
                value={moneda}
                onChange={(e) => {
                  setMoneda(e.target.value);
                }}
              >
                <option value="">Selecciona</option>
                 <option value="MXN">MXN</option>
                 <option value="USD">USD</option>
              </Input>
            </Col>
              <Col md={6}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Observaciones
                </Label>
                <Input
                  type="text"
                  placeholder="Observaciones"
                  value={observaciones}
                  required
                  onChange={(e) => {
                    setObservaciones(e.target.value);
                  }}
                />
              </Col>
              <Col md={12}>
              <h4 id="logoutBoton">
              TOTALES {total_kilos} kg. / {total_metros} mts. / {"$" + new Intl.NumberFormat("en-US").format(total_general)}
              </h4>
              </Col>
            </Row>

            {/* Tabla Insumos */}

              <Row>
                <Col md={2}>
                  <Label className="mr-sm-2">Insumos</Label>
                </Col>
                <Col md={1}>
                  <Label className="mr-sm-2">Unidad</Label>
                </Col>
                <Col md={1}>
                  <Label className="mr-sm-2">Minimo</Label>
                </Col>
                  <Col md={1}>
                    <Label className="mr-sm-2">Maximo</Label>
                  </Col>
                <Col md={1}>
                  <Label className="mr-sm-2">Cantidad</Label>
                </Col>
                <Col md={1}>
                  <Label className="mr-sm-2">Precio</Label>
                </Col>
                <Col md={1}>
                  <Label className="mr-sm-2">Total</Label>
                </Col>
              </Row>

              {inputFields.map((inputField) => (
                <div key={inputField.id}>
                  <Row>
                    <Col md={2}>
                      <Input
                        bsSize="sm"
                        name="insumos"
                        type="select"
                        value={inputField.insumos}
                        required
                        onChange={(event)=>{BuscaInsumo(inputField.id, event)}}
                      >
                        <option value="">Selecciona un Insumo</option>
                        {insumos
                          .sort((a, b) => (a.codigo > b.codigo ? 1 : -1))
                          .map((a) => {
                            if(a.proveedores[0]._id == selectedProveedor){
                            return (
                              <option value={a._id}>
                                {a.codigo} / {a.nombre}
                              </option>
                            );}
                          })}
                      </Input>
                    </Col>
                    <Col md={1}>
                        <Input
                         bsSize="sm"
                          name="unidad"
                          type="string"
                          value={inputField.unidad}
                          disabled
                        >
                        </Input>
                      </Col>

                    <Col md={1}>
                      <Input
                        bsSize="sm"
                        name="minimo"
                        type="number"
                        value={inputField.minimo}
                        disabled
                      />
                    </Col>

                    <Col md={1}> 
                      <Input
                        bsSize="sm"
                        name="maximo"
                        type="number"
                        value={inputField.maximo}
                        disabled
                      />
                    </Col>
                   

                    <Col md={1}>
                      <Input
                        bsSize="sm"
                        name="cantidad"
                        type="number"
                        placeholder="Cantidad"
                        value={inputField.cantidad}
                        required
                        onChange={(event) =>
                          handleChangeInput(inputField.id, event)
                        }
                      />
                    </Col>

                    <Col md={1}>
                      <Input
                        bsSize="sm"
                        name="precio"
                        type="number"
                        placeholder="Precio"
                        value={inputField.precio}
                        required
                        onChange={(event) =>
                          handleChangeInput(inputField.id, event)
                        }
                      />
                    </Col>

                    <Col md={2}>
                      <Input
                        bsSize="sm"
                        name="total"
                        type="number"
                        placeholder="Total"
                        value={inputField.total}
                        disabled
                        onChange={(event) =>
                          handleChangeInput(inputField.id, event)
                        }
                      />
                    </Col>
                    <Col>
                      <Button
                        size="sm"
                        className="btn"
                        color="danger"
                        disabled={inputFields.length === 1}
                        onClick={() => handleRemoveFields(inputField.id)}
                        tabindex="-1"
                      >
                        <i class="fas fa-minus"></i>
                      </Button>
                      <Button 
                      size="sm"
                      className="btn"
                      color="info"
                      onClick={handleAddFields}
                      tabindex="-1">
                      <i class="fas fa-plus"></i>
                      </Button>
                    </Col>
                  </Row>
                </div>
              ))}
              <Row>
              <Col md={12}>
              <h4 id="logoutBoton">
              TOTALES {total_kilos} kg. / {total_metros} mts. / {"$" + new Intl.NumberFormat("en-US").format(total_general)}
              </h4>
              </Col>
              </Row>
            {/* Termina Tabla Insumos */}

            <br />
            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
            <Button
              href="/ListadoOrdenesCompra"
              className="btn btn-danger"
              id="botonListado"
            >
              Regresar
            </Button>
          </Form>
          <br />
        </div>
      ) : undefined}
    </>
  );
}

export default OrdenesCompraCreate;
