import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import Baja from "../../Baja";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";

function ListadoInsumos() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_INSUMOS = process.env.REACT_APP_URL_INSUMOS;
  const URL_TIPOS_INSUMOS = process.env.REACT_APP_URL_TIPOS_INSUMOS;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_PROVEEDORES = process.env.REACT_APP_URL_PROVEEDORES;
  const URL_DEPARTAMENTOS = process.env.REACT_APP_URL_DEPARTAMENTOS;
  
  const [tiposInsumos, setTiposInsumos] = useState([]);
  const [selectedTiposInsumosFiltro, setSelectedTiposInsumosFiltro] = useState("");

  const [proveedores, setProveedores] = useState([]);
  const [departamentos, setDepartamentos] = useState([]);


  const [idEditInsumo, setIdEditInsumo] = useState("");
  const [selectedTipoEdit, setSelectedTipoEdit] = useState("");
  const [selectedProveedorEdit, setSelectedProveedorEdit] = useState("");
  const [selectedDepartamentoEdit, setSelectedDepartamentoEdit] = useState("");

  const [codigo, setCodigo] = useState("");
  const [nombre, setNombre] = useState("");
  const [composicion, setComposicion] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [unidad, setUnidad] = useState("");
  const [observaciones, setObservaciones] = useState("");
  const [costo, setCosto] = useState("");
  const [minimo, setMinimo] = useState(0);
  const [maximo, setMaximo] = useState(0);

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");


  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);

  const [activo, setActivo] = useState("");

  const [modalFoto, setModalFoto] = useState(false);
  const toggleFoto = () => setModalFoto(!modalFoto);

  const [file, setFile] = useState();
  const [photo, setPhoto] = useState();
  const URL_FILEPOST = process.env.REACT_APP_URL_UPPROFILE;

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useEffect(() => {
    axios
      .get(URL_INSUMOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allInsumos = response.data;
        // Array para pagination
        let arrayTabla = allInsumos
          .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
          .map((a) => {
            return {
              _id: a._id,
              activo: a.is_active,
              codigo: a.codigo,
              nombre: a.nombre,
              composicion: a.composicion,
              descripcion: a.descripcion,
              unidad: a.unidad,
              idTiposInsumos: a.tiposInsumos[0]._id,
              tiposInsumos: a.tiposInsumos[0].name,
              costo: a.costo,
              observaciones: a.observaciones,
              proveedor: a.proveedores[0].nombre_comercial + " " + a.proveedores[0].razon_social,
              idProveedor: a.proveedores[0]._id,
              departamentos: a.departamentos[0].name,
              idDepartamento: a.departamentos[0]._id,
              minimo: a.minimo,
              maximo: a.maximo,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);

        setComments(dataFinal);
        //
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_TIPOS_INSUMOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allTiposInsumos = response.data;
        setTiposInsumos(allTiposInsumos);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_PROVEEDORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allProveedores = response.data;
        setProveedores(allProveedores);
      })
      .catch((err) => {
        console.log(err);
      });

      axios
      .get(URL_DEPARTAMENTOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allDepartamentos = response.data;
        setDepartamentos(allDepartamentos);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const ListaInsumos = comments.sort((a, b) =>
    a.nombre > b.nombre ? 1 : -1
  );

  function PDFTabla() {
    const data = ListaInsumos.map((a) => {
      if (
        // (selectedTiposInsumosFiltro == 0 || selectedTiposInsumosFiltro == a.grupos[0]._id) &&
        activo == 0 ||
        activo == a.is_active
      ) {
        return [
          a.codigo,
          a.nombre,
          a.composicion,
          a.unidad,
          a.tiposInsumos,
          "$" + new Intl.NumberFormat("en-US").format(a.costo),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 60, 25);
    doc.text(`Listado Insumos`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Codigo",
          "Nombre",
          "Composicion",
          "Unidad",
          "Tipo",
          "Costo",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
    });
    doc.save(`Insumos.pdf`);
  }

  function excel() {
    const dataExcel = ListaInsumos.map((a) => {
      if (activo == 0 || activo == a.is_active) {
        return {
          Codigo: a.codigo,
          Nombre: a.nombre,
          Composicion: a.composicion,
          Unidad: a.unidad,
          Tipo: a.tiposInsumos,
          Costo: a.costo,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoInsumos";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoInsumos",
        sheetFilter: [
          "Codigo",
          "Nombre",
          "Composicion",
          "Unidad",
          "Tipo",
          "Costo",
        ],
        sheetHeader: [
          "Codigo",
          "Nombre",
          "Composicion",
          "Unidad",
          "Tipo",
          "Costo",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = ListaInsumos.map((a) => {
      if (
        // (selectedTiposInsumosFiltro == 0 || selectedTiposInsumosFiltro == a.grupos[0]._id) &&
        activo == 0 ||
        activo == a.is_active
      ) {
        return [
          a.codigo,
          a.nombre,
          a.composicion,
          a.unidad,
          a.tiposInsumos,
          "$" + new Intl.NumberFormat("en-US").format(a.costo),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 60, 25);
    doc.text(`Listado Insumos`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Codigo",
          "Nombre",
          "Composicion",
          "Unidad",
          "Tipo",
          "Costo",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado Insumos",
          email: mailTo,
          fileName: "ListadoInsumos.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Insumos.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Enviado con Exito!",
          showConfirmButton: false,
          timer: 2000,
        });
        setMailTo("")
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  function jalaFoto(
    idEdit
  ) {
    setPhoto("")
    const URL_GET_MEDIA = `${process.env.REACT_APP_URL_GETMEDIA}/${idEdit}`;
    axios
      .get(URL_GET_MEDIA, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((data) => {
        if (data.data.version) {
          setPhoto(data.data.version);
        }
      })
      .catch((err) => console.log(err));


    setIdEditInsumo(idEdit);
    toggleFoto();
  }


  function jalaInfo(
    codigo,
    nombre,
    composicion,
    unidad,
    descripcion,
    idTiposInsumos,
    costo,
    observaciones,
    idProveedor,
    idDepartamento,
    minimo,
    maximo,
    idEdit
  ) {

    setCodigo(codigo);
    setNombre(nombre);
    setComposicion(composicion);
    setUnidad(unidad);
    setDescripcion(descripcion);
    setSelectedTipoEdit(idTiposInsumos);
    setSelectedProveedorEdit(idProveedor);
    setSelectedDepartamentoEdit(idDepartamento);
    setCosto(costo);
    setObservaciones(observaciones);
    setMinimo(minimo);
    setMaximo(maximo);
    setIdEditInsumo(idEdit);
    toggleEdit();
  }

  function editInsumos(event) {
    event.preventDefault();
    const URL_INSUMOS_EDIT = `${process.env.REACT_APP_URL_INSUMOS}/${idEditInsumo}`;
    if (file == null) {
          axios.patch(
            URL_INSUMOS_EDIT,
            {
              codigo,
              nombre,
              composicion,
              unidad,
              tiposInsumos: selectedTipoEdit,
              proveedores: selectedProveedorEdit,
              departamentos: selectedDepartamentoEdit,
              costo,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
        .then(() => {
          axios.post(
            URL_LOGS,
            {
              tipo: "Editar Insumo",
              detalle: `${nombre} ${codigo}`,
              user: user.id,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          );

          toggleEdit();
          Swal.fire("Good job!", "Actualizado con exito", "success");
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response.data}`,
          });
          console.log(error);
        });
    } else {
          axios.patch(
            URL_INSUMOS_EDIT,
            {
              codigo,
              nombre,
              composicion,
              unidad,
              tiposInsumos: selectedTipoEdit,
              proveedores: selectedProveedorEdit,
              departamentos: selectedDepartamentoEdit,
              costo,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )

        .then(() => {
          const formData = new FormData();
          formData.append("file", file);
          formData.append("insumo", idEditInsumo);

          axios
            .post(URL_FILEPOST, formData, {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            })
            .then(() => {
              axios.post(
                URL_LOGS,
                {
                  tipo: "Editar Insumo",
                  detalle: `${nombre} ${codigo}`,
                  user: user.id,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              );

              toggleEdit();
              Swal.fire("Good job!", "Actualizado con exito", "success");
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                footer: `${error.response.data}`,
              });
              console.log(error);
            });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
          console.log(error);
        });
    }
  }

  const headers = [
    { name: "Activo", field: "activo", sortable: true },
    { name: "Codigo", field: "codigo", sortable: false },
    { name: "Nombre", field: "nombre", sortable: true },
    { name: "Composicion", field: "composicion", sortable: true },
    { name: "Unidad", field: "unidad", sortable: true },
    { name: "Tipo", field: "tiposInsumos", sortable: true },
    { name: "Proveedor", field: "proveedor", sortable: true },
    { name: "Departamento", field: "departamento", sortable: true },
    { name: "Acciones", field: "acciones", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.nombre.toLowerCase().includes(search.toLowerCase()) ||
          comment.composicion.toLowerCase().includes(search.toLowerCase()) ||
          comment.unidad.toLowerCase().includes(search.toLowerCase()) ||
          comment.tiposInsumos.toLowerCase().includes(search.toLowerCase()) ||
          comment.proveedor.toLowerCase().includes(search.toLowerCase()) ||
          comment.departamentos.toLowerCase().includes(search.toLowerCase()) ||
          comment.codigo.toString().includes(search)
      );
    }
    if (selectedTiposInsumosFiltro) {
      computedComments = computedComments.filter((e) =>
        e.idTiposInsumos.includes(selectedTiposInsumosFiltro)
      );
    }
    if (activo) {
      computedComments = computedComments.filter((e) =>
        e.activo.includes(activo)
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedTiposInsumosFiltro,
    activo,
  ]);

 

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_articulos ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
              {user.articulos_create ? (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  href="/InsumosCreate"
                >
                  Nuevo Insumo
                </Button>
              ) : (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  disabled
                >
                  Nuevo Insumo
                </Button>
              )}
              <Button
                size="sm"
                href="/MenuInsumos"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Listado Insumos</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>
          <h3 align="center">Insumos</h3>
          <div className="row">
            <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-4 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <Row>
            <Col md={2}>
              <Label>Tipo</Label>
              <Input
                bsSize="sm"
                type="select"
                value={selectedTiposInsumosFiltro}
                onChange={(e) => {
                  setSelectedTiposInsumosFiltro(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="">Selecciona</option>
                {tiposInsumos
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((a) => {
                    return <option value={a._id}>{a.name}</option>;
                  })}
              </Input>
            </Col>

            <Col md={2}>
              <Label>Activo</Label>
              <Input
                bsSize="sm"
                type="select"
                value={activo}
                required
                onChange={(e) => {
                  setActivo(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="">Selecciona</option>
                <option value="Si">Activos</option>
                <option value="No">Inactivos</option>
              </Input>
            </Col>
          </Row>
          <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tbody>
              {commentsData.map((a) => {
                {
                  return (
                    <tr>
                      <td>{a.activo}</td>
                      <td>{a.codigo}</td>
                      <td>{a.nombre}</td>
                      <td>{a.composicion}</td>
                      <td>{a.unidad}</td>
                      <td>{a.tiposInsumos} </td>
                      <td>{a.proveedor}</td>
                      <td>{a.departamentos}</td>
                      <td>
                        {user.articulos_create ? (
                          <div>
                            <Button
                              color="info"
                              id="Editar"
                              size="sm"
                              onClick={(e) =>
                                jalaFoto(
                                  a._id
                                )
                              }
                            >
                              <i class="fas fa-camera"></i>
                            </Button>

                         

                            <Button
                              color="info"
                              id="Editar"
                              size="sm"
                              onClick={(e) =>
                                jalaInfo(
                                  a.codigo,
                                  a.nombre,
                                  a.composicion,
                                  a.unidad,
                                  a.descripcion,
                                  a.idTiposInsumos,
                                  a.costo,
                                  a.observaciones,
                                  a.idProveedor,
                                  a.idDepartamento,
                                  a.minimo,
                                  a.maximo,
                                  a._id
                                )
                              }
                            >
                              <i class="far fa-edit"></i>
                            </Button>
                            <Baja
                              idStatus={a._id}
                              is_active={a.activo}
                              URL_BAJA={process.env.REACT_APP_URL_INSUMOS}
                            />
                          </div>
                        ) : undefined}
                      </td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : undefined}
      <br />

      <Modal size="lg" isOpen={modalEdit} toggle={toggleEdit}>
        <ModalHeader toggle={toggleEdit}>
          <h4>
            Editar insumo {codigo} / {nombre}
          </h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={3}>
              <Label>Codigo</Label>
              <Input
                type="text"
                placeholder="Codigo"
                value={codigo}
                required
                onChange={(e) => {
                  setCodigo(e.target.value);
                }}
              />
            </Col>
            <Col md={3}>
              <Label>Nombre</Label>
              <Input
                type="text"
                placeholder="Nombre"
                value={nombre}
                required
                onChange={(e) => {
                  setNombre(e.target.value);
                }}
              />
            </Col>

            <Col md={3}>
              <Label>Composicion</Label>
              <Input
                type="text"
                placeholder="Composicion"
                value={composicion}
                required
                onChange={(e) => {
                  setComposicion(e.target.value);
                }}
              />
            </Col>
            <Col md={3}>
              <Label>Descripcion</Label>
              <Input
                type="text"
                placeholder="Descripcion"
                value={descripcion}
                required
                onChange={(e) => {
                  setDescripcion(e.target.value);
                }}
              />
            </Col>
          </Row>

          <Row>
            <Col md={3}>
              <Label>Unidad</Label>
              <Input
                type="select"
                placeholder="Unidad"
                value={unidad}
                required
                onChange={(e) => {
                  setUnidad(e.target.value);
                }}
              >
                <option value="0">Unidad</option>
                <option value="Metros">Metros</option>
                <option value="Kilos">Kilos</option>
              </Input>
            </Col>
            <Col md={3}>
              <Label>Costo</Label>
              <Input
                type="number"
                placeholder="Costo"
                value={costo}
                required
                onChange={(e) => {
                  setCosto(e.target.value);
                }}
              />
            </Col>
            <Col md={3}>
              <Label className="mr-sm-2">Tipo</Label>
              <Input
                className="col-sm-12"
                type="select"
                value={selectedTipoEdit}
                required
                onChange={(e) => {
                  setSelectedTipoEdit(e.target.value);
                }}
              >
                <option value="0">Selecciona</option>
                {tiposInsumos
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((a) => {
                    return <option value={a._id}>{a.name}</option>;
                  })}
              </Input>
            </Col>
          <Col md={3}>
              <Label className="mr-sm-2">Proveedor</Label>
              <Input
                className="col-sm-12"
                type="select"
                value={selectedProveedorEdit}
                required
                onChange={(e) => {
                  setSelectedProveedorEdit(e.target.value);
                }}
              >
                <option value="0">Selecciona</option>
                {proveedores
                  .sort((a, b) => (a.nombre_comercial > b.nombre_comercial ? 1 : -1))
                  .map((a) => {
                    return <option value={a._id}>{a.nombre_comercial} {a.razon_social}</option>;
                  })}
              </Input>
            </Col>
          </Row>
          <Row>
             <Col md={3}>
                            <Label for="exampleEmail" className="mr-sm-2">
                              Minimo
                            </Label>
                            <Input
                              type="number"
                              value={minimo}
                              required
                              onChange={(e) => {
                                setMinimo(e.target.value);
                              }}
                            />
                          </Col>
                          <Col md={3}>
                            <Label for="exampleEmail" className="mr-sm-2">
                              Maximo
                            </Label>
                            <Input
                              type="number"
                              value={maximo}
                              required
                              onChange={(e) => {
                                setMaximo(e.target.value);
                              }}
                            />
                          </Col>
            <Col md={3}>
              <Label className="mr-sm-2">Departamento</Label>
              <Input
                className="col-sm-12"
                type="select"
                value={selectedDepartamentoEdit}
                required
                onChange={(e) => {
                  setSelectedDepartamentoEdit(e.target.value);
                }}
              >
                <option value="0">Selecciona</option>
                {departamentos
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((a) => {
                    return <option value={a._id}>{a.name} {a.name}</option>;
                  })}
              </Input>
            </Col>
            
            <Col md={3}>
              <Label>
                Observaciones
              </Label>
              <Input
                type="text"
                placeholder="Observaciones"
                value={observaciones}
                required
                onChange={(e) => {
                  setObservaciones(e.target.value);
                }}
              />
            </Col>
            <Col md={6}>
              <Label>
                Foto
              </Label>
              <Input
                type="file"
                onChange={(e) => {
                  setFile(e.target.files[0]);
                }}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={editInsumos}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>




          <Modal size="sm" isOpen={modalFoto} toggle={toggleFoto}>
        <ModalHeader toggle={toggleFoto}>
          <h4>
            Foto del Insumo
          </h4>
        </ModalHeader>
        <ModalBody>
        <Row>
            {photo ? (
              <h6 style={{ textAlign: "center" }}>
                <img
                  // loading="lazy"
                  src={`${process.env.REACT_APP_URL_FOTO_CLOUDINARY}/image/upload/v${photo}/bloomInsumos/${idEditInsumo}.jpg`}
                  alt=""
                />
              </h6>
            ) : <h4>No se ha subido una foto del Insumo....</h4>}
          </Row>
        </ModalBody>
      </Modal>

      {loader}
    </>
  );
}

export default ListadoInsumos;
