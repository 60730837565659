import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import Baja from "../../Baja";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import { func } from "prop-types";
import qrcode from "qrcode-js";

function ListadoInventariosInsumos() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_INVENTARIOS_INSUMOS = `${process.env.REACT_APP_URL_INVENTARIOS_INSUMOS}`;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_DEPARTAMENTOS = process.env.REACT_APP_URL_DEPARTAMENTOS;


  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");
  const [departamentos, setDepartamentos] = useState([]);



  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useEffect(() => {
    axios
      .get(`${URL_INVENTARIOS_INSUMOS}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allInventarios = response.data;
        // Array para pagination
        let arrayTabla = allInventarios
          .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
          .map((a) => {
            return {
              _id: a._id,
              cantidad: a.cantidad,
              codigo: a.insumos[0].codigo,
              nombre: a.insumos[0].nombre,
              idInsumo: a.insumos[0]._id,
              idTiposInsumos: a.insumos[0].tiposInsumos[0]._id,
              tiposInsumos: a.insumos[0].tiposInsumos[0].name,
              idDepartamento: a.insumos[0].departamentos[0]._id,
              departamentos: a.insumos[0].departamentos[0].name,

            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);
  
        setComments(dataFinal);
      })

      .catch((err) => {
        console.log(err);
      });
      axios
      .get(URL_DEPARTAMENTOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allDepartamentos = response.data;
        setDepartamentos(allDepartamentos);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);


  function PDFTabla() {
    let total_general = 0;

    let computedComments = comments;
    if (search) {
      computedComments = comments.filter(
        (comment) =>
          comment.nombre.toLowerCase().includes(search.toLowerCase()) ||
          comment.tiposInsumos.toLowerCase().includes(search.toLowerCase()) ||
          comment.cantidad.toString().includes(search) 
      );
    }


    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "cantidad" 
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "cantidad" )
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "cantidad" )
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    const data = computedComments
      .map((a) => {
          total_general = parseFloat(total_general) + parseFloat(a.cantidad);
          return [
            a.codigo,
            a.nombre,
            new Intl.NumberFormat("en-US").format(a.cantidad),
          ];
        }
      );
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 60, 25);
    doc.text(`Listado Inventarios`, 20, 40);
    doc.autoTable({
      // styles: {
      //     valign: 'center'
      // },
      headStyles: {
        valign: "middle",
        halign: "center",
      },
      footStyles: {
        valign: "middle",
        halign: "right",
      },
      head: [
        [
          "Codigo",
          "Nombre",
          "Cantidad",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        [
          "",
          "Total",
          new Intl.NumberFormat("en-US").format(total_general),
        ],
      ],
      showFoot: "lastPage",
    });
    doc.save(`Inventarios.pdf`);
  }

  function excel() {
    let computedComments = comments;
    const dataExcel = comments
      .filter(
        (comment) =>
          comment.nombre.toLowerCase().includes(search.toLowerCase()) ||
          comment.tiposInsumos.toLowerCase().includes(search.toLowerCase()) ||
          comment.cantidad.toString().includes(search) ||
          comment.codigo.toString().includes(search)
      )
      .map((a) => {
          return {
            Codigo: a.codigo,
            Nombre: a.nombre,
            Cantidad: a.cantidad,
          };
      });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoInventarios";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoInventarios",
        sheetFilter: [
          "Codigo",
          "Nombre",
          "Cantidad",
        ],
        sheetHeader: [
          "Codigo",
          "Nombre",
          "Cantidad",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    let total_general = 0;
    let computedComments = comments;
    if (search) {
      computedComments = comments.filter(
        (comment) =>
          comment.nombre.toLowerCase().includes(search.toLowerCase()) ||
          comment.tiposInsumos.toLowerCase().includes(search.toLowerCase()) ||
          comment.cantidad.toString().includes(search) 
      );
    }


    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "cantidad" 
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "cantidad" )
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "cantidad" )
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    const data = computedComments
      .map((a) => {
          total_general = parseFloat(total_general) + parseFloat(a.cantidad);
          return [
            a.codigo,
            a.nombre,
            new Intl.NumberFormat("en-US").format(a.cantidad),
          ];
        }
      );
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 60, 25);
    doc.text(`Listado Inventarios`, 20, 40);
    doc.autoTable({
      // styles: {
      //     valign: 'center'
      // },
      headStyles: {
        valign: "middle",
        halign: "center",
      },
      footStyles: {
        valign: "middle",
        halign: "right",
      },
      head: [
        [
          "Codigo",
          "Nombre",
          "Cantidad",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        [
          "",
          "Total",
          new Intl.NumberFormat("en-US").format(total_general),
        ],
      ],
      showFoot: "lastPage",
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado Inventarios",
          email: mailTo,
          fileName: "ListadoInventarios.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Inventarios.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Enviado con Exito!",
          showConfirmButton: false,
          timer: 2000,
        });
        setMailTo("")
        setMailTo("");
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }


  const headers = [
    { name: "Codigo", field: "codigo", sortable: true },
    { name: "Nombre", field: "nombre", sortable: true },
    { name: "Tipo de Insumo", field: "tiposInsumos", sortable: true },
    { name: "Departamentos", field: "departamentos", sortable: true },
    { name: "Cantidad", field: "cantidad", sortable: true },
  ];

  const commentsData = useMemo(() => {
let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.nombre.toLowerCase().includes(search.toLowerCase()) ||
          comment.tiposInsumos.toLowerCase().includes(search.toLowerCase()) ||
          comment.departamentos.toLowerCase().includes(search.toLowerCase()) ||
          comment.cantidad.toString().includes(search) ||
          comment.codigo.toString().includes(search)
      );
    }


    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "cantidad" 
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "cantidad" )
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "cantidad" )
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
  ]);



  let total = 0;



  return (
    <>
      <Header />
      <br />
      <br />
      {user.inventarios ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
              <Button
                size="sm"
                href="/MenuInventarios"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
             

            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Listado Inventarios</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>

          <h3 align="center">Inventario Insumos</h3>
          <div className="row">
            <div className="col-md-10">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>{" "}
            <div className="col-md-1 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
           
          </div>
          <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
 
            <tbody>
              {commentsData.map((a) => {
                total = total + parseFloat(a.cantidad);

                {
                  return (
                    <tr>
                      <td>{a.codigo}</td>
                      <td>{a.nombre}</td>
                      <td>{a.tiposInsumos} </td>
                      <td>{a.departamentos} </td>
                      <td>
                        {new Intl.NumberFormat("en-US").format(a.cantidad)}
                      </td>
                    </tr>
                  );
                }
              })}
              <tr>
                <td></td>
                <td></td>
                <td className="negrita">TOTAL</td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US").format(total)}
                </td>
              </tr>
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : undefined}


      {loader}
    </>
  );
}

export default ListadoInventariosInsumos;
