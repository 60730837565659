import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { Col, Row, Button, Form, FormGroup, Label, Input, Modal, ModalHeader, ModalBody } from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";

function InsumosCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_INSUMOS = process.env.REACT_APP_URL_INSUMOS;
  const URL_PROVEEDORES = process.env.REACT_APP_URL_PROVEEDORES;
  const URL_DEPARTAMENTOS = process.env.REACT_APP_URL_DEPARTAMENTOS;
  const URL_TIPOS_INSUMOS = process.env.REACT_APP_URL_TIPOS_INSUMOS;

  const [codigo, setCodigo] = useState("");
  const [nombre, setNombre] = useState("");
  const [name, setName] = useState("");
  const [composicion, setComposicion] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [unidad, setUnidad] = useState("Kilos");
  const [proveedores, setProveedores] = useState([]);
  const [selectedProveedor, setSelectedProveedor] = useState("");
  const [departamentos, setDepartamentos] = useState([]);
  const [selectedDepartamento, setSelectedDepartamento] = useState("");
  const [tiposInsumos, setTiposInsumos] = useState([]);
  const [selectedTipo, setselectedTipo] = useState("");
  const [observaciones, setObservaciones] = useState("NA");
  const [costo, setCosto] = useState("");
  const [minimo, setMinimo] = useState(0);
  const [maximo, setMaximo] = useState(0);



  const [validaBoton, setValidaBoton] = useState(true);

  useEffect(() => {
    axios
      .get(URL_TIPOS_INSUMOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allTiposInsumos = res.data;
        setTiposInsumos(allTiposInsumos);
      })
      .catch((err) => {
        console.log(err);
      });

      axios
      .get(URL_PROVEEDORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allProveedores = res.data;
        setProveedores(allProveedores);
      })
      .catch((err) => {
        console.log(err);
      });

      axios
      .get(URL_DEPARTAMENTOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allDepartamentos = res.data;
        setDepartamentos(allDepartamentos);
      })
      .catch((err) => {
        console.log(err);
      });
   
   
  }, []);



  const saveInsumos = async (event) => {
    event.preventDefault();
    setValidaBoton(false);
    try {
      await axios
        .post(
          URL_INSUMOS,
          {
            codigo,
            nombre,
            composicion,
            descripcion,
            unidad,
            costo,
            tiposInsumos: selectedTipo,
            observaciones,
            proveedores: selectedProveedor,
            departamentos: selectedDepartamento,
            minimo,
            maximo
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )   .then(() => {
                  axios.post(
                    URL_LOGS,
                    {
                      tipo: "Crear Insumo",
                      detalle: `${nombre}`,
                      user: user.id,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  );
                  axios.post(
                    URL_LOGS,
                    {
                      tipo: "Crear Departamento",
                      detalle: name,
                      user: user.id,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                      },
                    }
                  );
                  Swal.fire("Good job!", "Creado con exito", `success`);
                  setTimeout(() => {
                    window.location.reload();
                  }, 1000);
                })
                .catch((error) => {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    footer: `${error.response.data}`,
                  });
                  console.log(error);
                  setValidaBoton(true)
                });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    }
  };


  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_articulos ? (
        <div className="card container">
          <h3 align="center">Nuevo Insumo</h3>
          <Form onSubmit={saveInsumos}>
            <Row>
            <Col md={3}>
                <Label>Codigo</Label>
                <Input
                  type="text"
                  placeholder="Codigo"
                  value={codigo}
                  required
                  onChange={(e) => {
                    setCodigo(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Nombre</Label>
                <Input
                  type="text"
                  placeholder="Nombre"
                  value={nombre}
                  required
                  onChange={(e) => {
                    setNombre(e.target.value);
                  }}
                />
              </Col>
              
              <Col md={3}>
                <Label>Composicion</Label>
                <Input
                  type="text"
                  placeholder="Composicion"
                  value={composicion}
                  required
                  onChange={(e) => {
                    setComposicion(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Descripcion</Label>
                <Input
                  type="text"
                  placeholder="Descripcion"
                  value={descripcion}
                  required
                  onChange={(e) => {
                    setDescripcion(e.target.value);
                  }}
                />
              </Col>
            
            </Row>

            <Row>
             
              <Col md={3}>
                <Label>Costo</Label>
                <Input
                  type="text"
                  placeholder="Costo"
                  value={costo}
                  required
                  onChange={(e) => {
                    setCosto(e.target.value);
                  }}
                />
              </Col>
             
              <Col md={3}>
                <Label className="mr-sm-2">Tipo</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedTipo}
                  required
                  onChange={(e) => {
                    setselectedTipo(e.target.value);
                  }}
                >
                  <option value="0">Selecciona</option>
                  {tiposInsumos
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </Col>
             
              <Col md={3}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Minimo
                </Label>
                <Input
                  type="number"
                  value={minimo}
                  required
                  onChange={(e) => {
                    setMinimo(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Maximo
                </Label>
                <Input
                  type="number"
                  value={maximo}
                  required
                  onChange={(e) => {
                    setMaximo(e.target.value);
                  }}
                />
              </Col>
             
            </Row>
            <Row>
            <Col md={3}>
                <Label className="mr-sm-2">Proveedor</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedProveedor}
                  required
                  onChange={(e) => {
                    setSelectedProveedor(e.target.value);
                  }}
                >
                  <option value="0">Selecciona</option>
                  {proveedores
                    .sort((a, b) => (a.nombre_comercial > b.nombre_comercial ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.nombre_comercial} {a.razon_social}</option>;
                    })}
                </Input>
              </Col>
              <Col md={3}>
                <Label className="mr-sm-2">Departamento</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedDepartamento}
                  required
                  onChange={(e) => {
                    setSelectedDepartamento(e.target.value);
                  }}
                >
                  <option value="0">Selecciona</option>
                  {departamentos
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name} </option>;
                    })}
                </Input>
              </Col>
              <Col md={6}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Observaciones
                </Label>
                <Input
                  type="text"
                  placeholder="Observaciones"
                  value={observaciones}
                  required
                  onChange={(e) => {
                    setObservaciones(e.target.value);
                  }}
                />
              </Col>
            
            </Row>
            <br />
            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
            <Button
              href="/ListadoInsumos"
              className="btn btn-danger"
              id="botonListado"
            >
              Regresar
            </Button>
          </Form>
          <br />

        </div>
      ) : undefined}
    </>
  );
}

export default InsumosCreate;
